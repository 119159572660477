<template>
	<div data-component="lesson-deal-list-item">
		<component
			:is="getComponent"
			v-bind="getProps"
		>
			<template #actions="{ deal }">
				<btn
					v-if="isAdded(deal)"
					@click.native="removeDealFromLesson(deal)"
					text="Remove"
					colour="red"
					size="sm"
				/>
				<btn
					v-else
					@click.native="addDealToLesson(deal)"
					text="Add"
					size="sm"
				/>
			</template>
			<template #groupActions="{ group }">
				<span
					v-if="addedInGroupCount(group) > 0"
					data-element="added-count"
				>
					{{ addedInGroupCount(group) }}
				</span>
			</template>
		</component>
		<slot name="actions" />
	</div>
</template>

<script>

	import Deal   from '@/components/dealLibrary/Deal';
	import Group  from '@/components/dealLibrary/Group';
	import Btn from '@/components/ui/Btn';

	export default {
		components: {
			Deal,
			Group,
			Btn
		},
		props: {
			group: {
				type: Object,
				required: true
			},
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({}),
		computed: {
			getComponent () {
				if (this.group?.deals?.length > 1) {
					return 'Group';
				}
				return 'Deal';
			},
			getProps () {
				if (this.getComponent === 'Deal') {
					return {
						deal: this.group.deals[0]
					};
				}
				return {
					group: this.group
				};
			},
			getLessonDeals () {
				return this.lesson.deals || [];
			}
		},
		methods: {
			isAdded (deal) {
				return this.getLessonDeals.some(item => item.id === deal.id);
			},
			addedInGroupCount (group) {
				return this.getLessonDeals
					.filter(deal => group.deals
						.some(item => item.id === deal.id)
					).length;
			},
			addDealToLesson (deal) {
				this.$emit('addDealToLesson', deal);
			},
			removeDealFromLesson (deal) {
				this.$emit('removeDealFromLesson', deal);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='lesson-deal-list-item'] {
		[data-element='added-count'] {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			width: rem(22);
			height: rem(22);
			font-size: rem(14);
			line-height: 1;
			font-weight: 700;
			text-align: center;
			color: $c-white;
			background-color: $c-brand-red-lighter-1;
			border-radius: 50%;
		}
	}

</style>
