<template>
	<div data-component="edit-lesson-deals">
		<div
			v-if="getLessonDeals.length > 0"
			data-element="deals-list"
		>
			<deal
				v-for="deal in getLessonDeals"
				:key="deal.id"
				context="lesson"
				:deal="deal"
			>
				<template #actions>
					<btn
						@click.native="removeDealFromLesson(deal)"
						text="Remove"
						colour="red"
						size="sm"
					/>
				</template>
			</deal>
			<actions
				:actions="getDealsActions"
				size="sm"
				@actionClick="onActionClick"
			/>
		</div>
		<p v-else data-element="list-empty-state">
			List is empty
		</p>
		<actions
			:actions="getActions"
			@actionClick="onActionClick"
			:isWaiting="isWaiting"
		/>
		<lesson-deals-library
			v-if="dealsLibraryIsVisible"
			:lesson="lesson"
			@addDealToLesson="addDealToLesson"
			@removeDealFromLesson="removeDealFromLesson"
		/>
		<portal
			to="modal"
			v-if="previewIsVisible"
		>
			<modal-inner
				@close="togglePreview"
				heading="Preview deals"
			>
				<deal-summaries
					:deals="getLessonDeals"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import Deal  from '@/components/dealLibrary/Deal';
	import Btn from '@/components/ui/Btn';
	import Actions from '@/components/ui/Actions';
	import LessonDealsLibrary from '@/components/lessons/LessonDealsLibrary';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import DealSummaries from '@/components/diagrams/DealSummaries';
	import routeParams from '@/mixins/routeParams';
	import actionClick from '@/mixins/actionClick';
	import api from '@/services/api';

	export default {
		components: {
			Deal,
			Btn,
			Actions,
			LessonDealsLibrary,
			ModalInner,
			DealSummaries
		},
		mixins: [routeParams, actionClick],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			isWaiting: undefined,
			dealsLibraryIsVisible: false,
			previewIsVisible: false
		}),
		computed: {
			getLessonDeals () {
				return this.lesson.deals || [];
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							actionId: 'saveDeals',
							btnId: 'saveDeals'
						},
						{
							text: 'Close',
							route: this.getLessonRoute
						}
					],
					secondary: [
						{
							text: this.dealsLibraryIsVisible ? 'Hide deals library' : 'Browse deals library',
							actionId: 'toggleDealsLibrary',
							btnId: 'toggleDealsLibrary'
						}
					]
				};
			},
			getDealsActions () {
				return {
					primary: [
						{
							text: 'Preview',
							actionId: 'togglePreview',
							btnId: 'togglePreview'
						}
					],
					secondary: [
						{
							text: 'Clear list',
							actionId: 'clearDeals',
							btnId: 'clearDeals'
						}
					]
				};
			}
		},
		methods: {
			toggleDealsLibrary () {
				this.dealsLibraryIsVisible = !this.dealsLibraryIsVisible;
			},
			togglePreview () {
				this.previewIsVisible = !this.previewIsVisible;
			},
			addDealToLesson (deal) {
				this.lesson.deals = [...this.getLessonDeals, deal];
			},
			removeDealFromLesson (deal) {
				this.lesson.deals = this.getLessonDeals.filter(item => item.id !== deal.id);
			},
			clearDeals () {
				this.lesson.deals = [];
			},
			async saveDeals () {
				this.isWaiting = 'saveDeals';
				const response = await api[this.getApiRoot].updateLessonById({
					fields: [
						'id'
					],
					lesson: {
						deals: this.getLessonDeals.map(({ id, deal }) => ({
							id,
							startWithEmptyAuction: false
						}))
					},
					lessonId: this.getLessonId
				});
				this.isWaiting = undefined;
				if (response) {
					this.$store.commit('ui/showNotification', {
						notification: 'The study deals has been saved'
					});
				} else {
					console.error('Something went wrong. Empty response received.');
				}
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='edit-lesson-deals'] {
		display:flex;
		flex-direction: column;

		[data-element='deals-list'] {
			[data-component='actions'] {
				padding:rem(10) 0;
			}
		}

		[data-element='list-empty-state'] {
			width:100%;
			padding:rem(20) 0;
			font-size:rem(18);
			color:$c-brand-grey;
			text-align:center;
		}
	}

</style>
