<template>
	<div data-component="lesson-deals-library">
		<search
			@search="onSearch"
			inputPlaceholder="Search by deal name"
		/>
		<filters
			:filters="getFilterOptions"
			@filter="onFilter"
		/>
		<div
			data-element="list"
			v-if="getGroups"
		>
			<lesson-deal-list-item
				v-for="(group, key) in getGroups"
				:key="key"
				:group="group"
				:lesson="lesson"
				@addDealToLesson="addDealToLesson"
				@removeDealFromLesson="removeDealFromLesson"
			/>
		</div>
		<records-count
			:pagination="getPagination"
			:total="getTotalItems"
		/>
		<pagination
			v-bind="getPagination"
			@paginate="onPaginate"
		/>
	</div>
</template>

<script>

	import Search from '@/components/ui/views/SearchInput';
	import Filters from '@/components/ui/views/filters/Filters';
	import LessonDealListItem  from '@/components/lessons/LessonDealListItem';
	import RecordsCount  from '@/components/ui/views/RecordsCount';
	import Pagination    from '@/components/ui/views/Pagination';
	import routeParams from '@/mixins/routeParams';

	export default {
		components: {
			Search,
			Filters,
			LessonDealListItem,
			RecordsCount,
			Pagination
		},
		mixins: [routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			isWaiting: false
		}),
		computed: {
			getLessonDeals () {
				return this.lesson.deals || [];
			},
			getFilterOptions () {
				return this.$store.getters['dealLibrary/getFilterOptions'] || [];
			},
			getGroups () {
				return this.$store.getters['dealLibrary/getGroups'];
			},
			getPagination () {
				return this.$store.getters['dealLibrary/getPagination'];
			},
			getTotalItems () {
				return this.$store.getters['dealLibrary/getTotalItems'] || 0;
			}
		},
		methods: {
			async setGroups () {
				await this.$store.dispatch('dealLibrary/setGroups');
				this.$store.commit('ui/setLoadingIsHidden');
			},
			onFilter (value) {
				this.$store.commit('dealLibrary/setCurrentPage', {
					currentPage: 1
				});
				this.$store.commit('dealLibrary/setActiveFilters', {
					activeFilters: value
				});
				this.setGroups();
			},
			onSearch (value) {
				this.$store.commit('dealLibrary/setSearchString', {
					searchString: value
				});
				this.setGroups();
			},
			onPaginate (value) {
				this.$store.commit('dealLibrary/setCurrentPage', {
					currentPage: value
				});
				this.setGroups();
			},
			addDealToLesson (deal) {
				this.$emit('addDealToLesson', deal);
			},
			removeDealFromLesson (deal) {
				this.$emit('removeDealFromLesson', deal);
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='select-deals-modal'] {
	}

</style>
